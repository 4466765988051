import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import "src/assets/js/custom.3.js";
//declare var custom: any;
declare function homeSlider(): any;
declare function windowScroll(): any;
declare function serviceSlider(): any;
declare function testimonialSlider(): any;
declare function slickSlider(): any;
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  constructor() { }

  public ngOnInit() {
    /**
    * ----------------------------------------------
    * Home banner carousel call
    * ----------------------------------------------
    */
   homeSlider();

    /**
    * ----------------------------------------------
    * Nav Scroll active section class call
    * ----------------------------------------------
    */
    windowScroll();
   
    /**
   * ----------------------------------------------
   * service slider call
   * ----------------------------------------------
   */

    serviceSlider();
  
    /**
    * ----------------------------------------------
    * Testimonial slider call
    * ----------------------------------------------
    */
    testimonialSlider();

    /**
    * ----------------------------------------------
    * Slick Slider Call
    * ----------------------------------------------
    */
    slickSlider()
  }
}


// import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
// import videojs from 'video.js';

// // Declara a lib do videojs como externa ao angular
// declare let videojs: any;

// @Component({
//   selector: 'app-home',
//   templateUrl: './home.component.html',
//   styleUrls: ['./home.component.css']
// })
// export class HomeComponent implements AfterViewInit , OnInit {
//   // Titulo do component
//   title = 'Player com Video.JS';
//   // Instancia do video.js.
//   vidObj: any;
//   // Poster para ser usado no video.js
//   poster: string;
//   // URL do video a ser reproduzido.
//   video: string;
//   video_type: string;
//   // Subtitles
//   subtitle_en: string;
//   subtitle_pt: string;
//   // Acessa o elemento video do html5 via viewchild.
//   @ViewChild('myvid') vid: ElementRef;
  
//   constructor(private domSanitizer: DomSanitizer) { }

//   ngOnInit() {
//     // Poster para ser usado no video.js
//     //this.poster = '/src/assets/videos/Friends_-__1x24__-_The_One_where_Rachel_finds_Out_1493837688_360p.jpg';
//     // Video
//     this.video = '/assets/videos/Friends_1_24/Friends_1_24.mkv';
//     this.video_type = 'video/mp4'
//     // Legenda
//     this.subtitle_en = '/assets/videos/Friends_1_24/Friends_1_24_en.vtt';
//     this.subtitle_pt = '/assets/videos/Friends_1_24/Friends_1_24_pt.vtt';
//   }

//   ngAfterViewInit() {
//     const options = {
//       controls: true,
//       autoplay: false,
//       preload: 'auto',
//       techOrder: ['html5']
//     };

//     this.vidObj = new videojs(this.vid.nativeElement, options, function onPlayerReady() {
//       videojs.log('Your player is ready!');
//     });
//   }
// }
