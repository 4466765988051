import { Contact } from './../model/contact';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseResult } from '../model/responseResult';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendContact(contact: Contact): any {
    return this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/contact`, contact);
    // return call.subscribe(response => {
    //     if (response.typeResponse == EnumTypeResponse.Success) {
    //         return response;
    //     } else {
    //         return null;
    //     }
    // });
  }
}
