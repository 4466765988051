<div class="container">
    <!--==== Section Heading Text =====-->
    <div class="section-header">
        <h2><small class="text-primary">Nossos</small> Treinamentos</h2>
        <p class="subtitle">
            O aluno da English Coffee pode escolher o pacote que mais identifica ao seu perfil, porém todos aplicamos 100% do passo a passo do método.
        </p>
    </div>
    <div class="row">
        <!--==== Single Pricing Box =====-->
        <div class="col-md-6 mb-30" data-aos="zoom-in" data-aos-duration="300">
            <div class="pricing-card-box-3 box-shadow">
                <div class="pricing-header">
                    <h4 class="plan-title">VIP</h4>
                    <div class="plan-price">
                        <div class="price-text">
                            <p>O pacote VIP atende um aluno por vez. Sendo o professor e o aluno.
                                Podendo fazer suas aulas com flexibilidade de horário, em qualquer período do dia. Desde que, previamente agendado.</p>
                        </div>
                    </div>
                </div>
                <div class="pricing-body">
                    <ul class="list-unstyled">
                        <li><i class="icon-check"></i> 1 aluno x teacher</li>
                        <li><i class="icon-check"></i> 1x na semana</li>
                        <li><i class="icon-check"></i> Duração de 18 meses (tempo regular)</li>
                    </ul>
                    <div class="pricing-footer">
                        <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="btn btn-primary btn-block" title="Tenho interesse">
                            Tenho interesse
                            <i class="icon-basket fa-lg"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--==== Single Pricing Box =====-->
        <div class="col-md-6 mb-30" data-aos="zoom-in" data-aos-duration="400">
            <div class="pricing-card-box-3 recommanded-card box-shadow">
                <div class="pricing-header">
                    <h4 class="plan-title">Semi VIP</h4>
                    <div class="plan-price">
                        <div class="price-text">
                            <p>O pacote Semi VIP atende 3 alunos por vez. Sendo o professor e três alunos e o professor. 
                                Podendo você estudar com seus amigos ou até mesmo, entrar em uma turma existente.</p>
                        </div>
                    </div>
                </div>
                <div class="pricing-body">
                    <ul class="list-unstyled">
                        <li><i class="icon-check"></i> 3 alunos x teacher</li>
                        <li><i class="icon-check"></i> 1x na semana</li>
                        <li><i class="icon-check"></i> Duração de 18 meses (tempo regular)</li>
                    </ul>
                    <div class="pricing-footer">

                        <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="btn btn-primary btn-block" title="Tenho interesse">
                            Tenho interesse
                            <i class="icon-basket fa-lg"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--==== Important 1 =====-->
    <div class="row">
        <div class="col-md-12 mb-30" data-aos="zoom-in" data-aos-duration="300">
            <p class="subtitle">
                Como não trabalhamos com a gramática solta e, sim, com o Inglês Contextualizado, 
                temos uma grade de evolução, ou seja, habilidade que o a aluno adquire em cada 6 meses. Veja abaixo:
            </p>
        </div>
    </div>
    <!--==== Blogs Item =====-->
    <div class="row">
        <!--==== Single Blog Box =====-->
        <div class="col-md-4 mb-30" data-aos="fade-up" data-aos-duration="300">
            <div class="blog-box-1 theme-1 box-shadow">
                <img src="assets/images/bg-5.jpg" alt="Curso de inglês para empresas" style="margin: auto; width: 100%;">
                <div class="blog-body">
                    <ul class="blog-meta">
                        <li>
                            <i class="fa fa-user text-primary"></i>6 MESES
                        </li>
                        <li>
                            <i class="fa fa-line-chart text-primary"></i> 33% Performance
                        </li>
                    </ul>
                    <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="blog-title" title="Tenho interesse">
                        <h6>🚩Módulo 1</h6>
                    </a>
                    <p class="blog-description">
                        Conhecer algumas palavras e frases, conseguir responder as perguntas e entender instruções simples, 
                        ter capacidade básica de se comunicar em situações cotidianas; conseguir entender inglês quando falado devagar e claramente.
                    </p>
                    <a class="more-link" href="https://www.instagram.com/englishcoffeemethod/?hl=pt" target="_blank" title="Quero Conhecer">
                        Quero Conhecer
                    </a>    
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-30" data-aos="fade-up" data-aos-duration="400">
            <div class="blog-box-1 theme-1 box-shadow">
                <img src="assets/images/bg-20.jpg" alt="Curso de inglês para adultos" style="margin: auto; width: 100%;">
                <div class="blog-body">
                    <ul class="blog-meta">
                        <li>
                            <i class="fa fa-user text-primary"></i>6 MESES
                        </li>
                        <li>
                            <i class="fa fa-line-chart text-primary"></i> 33% Performance
                        </li>
                    </ul>
                    <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="blog-title" title="Tenho interesse">
                        <h6>🚩Módulo 2</h6>
                    </a>
                    <p class="blog-description">
                        Entender a maioria das perguntas e afirmações a uma velocidade normal, 
                        conseguir manter conversações com pessoas cuja língua materna é o Inglês, conseguir usar o Inglês para fazer compras, 
                        fazer pedidos em restaurantes e pedir instruções, conseguir expressar fatos e opiniões, 
                        debater e defender uma posição de maneira culturalmente aceitável.
                    </p>
                    <a class="more-link" href="https://www.instagram.com/englishcoffeemethod/?hl=pt" target="_blank" title="Quero Conhecer">
                        Quero Conhecer
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-30" data-aos="fade-up" data-aos-duration="500">
            <div class="blog-box-1 theme-1 box-shadow">
                <img src="assets/images/bg-9.jpg" alt="Curso de inglês para jovens" style="margin: auto; width: 100%;">
                <div class="blog-body">
                    <ul class="blog-meta">
                        <li>
                            <i class="fa fa-user text-primary"></i>6 MESES
                        </li>
                        <li>
                            <i class="fa fa-line-chart text-primary"></i> 33% Performance
                        </li>
                    </ul>
                    <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="blog-title" title="Tenho interesse">
                        <h6>🚩Módulo 3</h6>
                    </a>
                    <p class="blog-description">
                        Conseguir usar o Inglês com relativa fluência e precisão, ajudando os demais colegas de classe, inclusive na leitura e
                        redação, conseguir participar da maioria das conversas, estar preparado para a maioria das situações sociais,
                        conhecer o significado de uma grande variedade de expressões idiomáticas, conseguir manter conversações longas em Inglês com pessoas nativas.
                    </p>
                    <a class="more-link" href="https://www.instagram.com/englishcoffeemethod/?hl=pt" target="_blank" title="Quero Conhecer">
                        Quero Conhecer
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
