import { Component, OnInit } from '@angular/core';

@Component({ selector: 'sd-sidebar', templateUrl: './sidebar.component.html' })
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
