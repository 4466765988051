<!-- **************************************-->
<!-- **************Preloader**************-->
<!-- **************************************-->
<div class="preloader" *ngIf="submitted" style="background: black;opacity: 0.3;">
    <div class="ball-grid-pulse">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- **************************************-->
<!-- **************Scroll Top Button**************-->
<!-- **************************************-->
<div class="scroll-top-btn" data-scroll-goto="0">
    <a href="#home" class="scroll-top">
        <span class="scroll-top-icon"></span>
    </a>
</div>
<!-- **************************************-->
<!-- **************Main Page Wrapper**************-->
<!-- **************************************-->
<div class="page-wrapper">
    <header class="header link-nav">
        <nav class="navbar navbar-expand-lg">
            <div class="container" wfd-id="78">
                <!--==== Logo Area =====-->
                <div class="navbar-header" wfd-id="81">
                    <!--==== Logo =====-->
                    <!-- <a class="navbar-brand" href="#">
                        <img src="assets/images/logo/logo-v1.png" alt="Letstart">
                    </a> -->
                </div>
                <!--==== Main Menu =====-->
                <div wfd-id="26" class="nav-get-started pull-right">
                <span class="button-text text-white pr-3">Já possui uma conta?</span>
                    <a href="https://admin.englishcoffee.com.br/" class="btn btn-primary btn-sm mt-0 p-0">Login</a>
                </div>
            </div>
        </nav>
    </header>
    <!--==== Full Screen =====-->
    <div class="overlay opacity-6"></div>
    <div class="fullscreen bg-coming-soon no-header" style="overflow: hidden auto;">
        <div class="row">
            <!--==== Center coloumn vertically and horizontally =====-->
            <div class="col-12 d-flex justify-content-center white-text" style="position: relative;">
                <!--==== Coming soon Content =====-->
                <div class="coming-soon-content" style="margin: auto; position: absolute;">
                    <div class="mt-50 mb-50">
                        <div class="logo">
                            <img src="../../../assets/images/logo/logo-english-coffee.png" alt="Letstart" style="width: 150px !important;">
                        </div>
                    </div>
                    <div class="cd100">
                        <div class="row">
                            <!--==== Single Counter =====-->
                            <div class="col-12">
                                <!--==== Home Banner Text =====-->
                                <div class="home-slide-content white-text pb-2" style="padding-top: 0 !important;">
                                    <!--==== Home Banner Heading Text =====-->
                                    <h1 class="font-weight-light">We are English Coffee</h1>
                                    <h2 class="small-heading cd-headline clip my-2">
                                        <span>Nosso método é </span>
                                        <span class="cd-words-wrapper">
                                            <b class="is-visible"> inglês contextualizado</b>
                                            <b class="is-hidden"> sem gramática</b>
                                            <b class="is-hidden"> online</b>
                                            <b class="is-hidden"> rápido</b>
                                        </span>
                                    </h2>
                                    <!-- <h4 class="font-weight-light">Our website is under construction, follow us for update now!</h4> -->
                                    <!--==== Home Banner Btn's =====-->
                                    <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="btn btn-outline-white mt-30 mr-2" title="Aprenda online">
                                        Aprenda online!
                                    </a>
                                    <a href="https://www.facebook.com/englishcoffeemethod/" target="_blank" class="btn btn-white mt-30 btn-metodo" title="Conheça o nosso método" style="text-transform: inherit;">
                                        Conheça o nosso método
                                    </a>
                                    <div class="mt-30">
                                        <p>
                                            Nosso site está em contrução, informe seu e-mail abaixo para ser avisado quando estiver tudo pronto!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- **************************************-->
                    <!-- **************About Us**************-->
                    <!-- **************************************-->
                    <section class="row justify-content-center pt-2" id="contact" data-scroll-index="1">
                        <!--==== Subscribe Form =====-->
                        <div class="join-us-form col-xl-12 col-lg-12 col-md-12 col-sm-12 col-10">
                            <form class="form" [formGroup]="contactForm" id="contactForm" class="form needs-validation" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome" name="name"
                                                maxlength="80" [ngClass]="{ 'is-invalid': !validContact && f.name.errors }" required>
                                            <div *ngIf="!validContact && f.name.errors" class="invalid-feedback text-left">
                                                <div *ngIf="f.name.errors.required">Por favor, informe o nome do aluno.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="email" id="email" formControlName="email" class="form-control" placeholder="E-mail" name="email"
                                                maxlength="100" [ngClass]="{ 'is-invalid': !validContact && f.email.errors }" required>
                                            <div *ngIf="!validContact && f.email.errors" class="invalid-feedback text-left">
                                                <div *ngIf="f.email.errors.required">Por favor, informe o seu e-mail.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="phone" id="phone" name="phone" formControlName="phone" class="form-control" name="phone"
                                            maxlength="17" [textMask]="{mask: phoneMask}" placeholder="Telefone" 
                                            [ngClass]="{ 'is-invalid': !validContact && f.phone.errors }" required>
                                            <div *ngIf="!validContact && f.phone.errors" class="invalid-feedback text-left">
                                                <div *ngIf="f.phone.errors.required">Por favor, informe o seu telefone.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea id="message" formControlName="message" class="form-control" placeholder="Mensagem (opcional)" name="message"
                                            rows="4" maxlength="300" [ngClass]="{ 'is-invalid': !validContact && f.message.errors }" required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="w-100 pt-15 pr-20 text-right">
                                            <button type="submit" id="subscribe" style="margin-right: 10px;"><i class="icon-arrow-right-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="w-100 pt-15 pr-20 text-left" *ngIf="returnMessage != ''">
                                            <div  class="alert alert-dismissible" [ngClass]="msgReturnSuccess ? 'alert-info' : 'alert-danger'" data-auto-dismiss role="alert">
                                                <button class="close" aria-label="close" data-dismiss="alert">&times;</button>
                                                <span id="alertMsg">{{returnMessage}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>                    
                </div>
            </div>
        </div>
    </div>
</div>
