<!-- **************************************-->
<!-- **************Preloader**************-->
<!-- **************************************-->
<div class="preloader" *ngIf="submitted" style="background: black;opacity: 0.3;">
    <div class="ball-grid-pulse">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="container">
    <!--==== Section Heading Text =====-->
    <div class="section-header">
        <h2><small class="text-primary">Deixe uma mensagem para nós</small>Contato</h2>
    </div>
    <div class="preloader">
        <div class="ball-rotate">
            <div></div>
        </div>
    </div>
    <!--==== Contact Us form =====-->
    <form id="validation-form" class="h-100" novalidate="novalidate" [formGroup]="contactForm" id="contactForm" (ngSubmit)="onSubmit()" data-aos="fade" data-aos-duration="400">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome" name="name"
                        maxlength="80" [ngClass]="{ 'is-invalid': !validContact && f.name.errors }" required>
                    <div *ngIf="!validContact && f.name.errors" class="invalid-feedback text-left">
                        <div *ngIf="f.name.errors.required">Por favor, informe o nome do aluno.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <input type="email" id="email" formControlName="email" class="form-control" placeholder="E-mail" name="email"
                        maxlength="100" [ngClass]="{ 'is-invalid': !validContact && f.email.errors }" required>
                    <div *ngIf="!validContact && f.email.errors" class="invalid-feedback text-left">
                        <div *ngIf="f.email.errors.required">Por favor, informe o seu e-mail.</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input type="phone" id="phone" name="phone" formControlName="phone" class="form-control" name="phone"
                    maxlength="17" [textMask]="{mask: phoneMask}" placeholder="Telefone" 
                    [ngClass]="{ 'is-invalid': !validContact && f.phone.errors }" required>
                    <div *ngIf="!validContact && f.phone.errors" class="invalid-feedback text-left">
                        <div *ngIf="f.phone.errors.required">Por favor, informe o seu telefone.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <textarea id="message" formControlName="message" class="form-control" placeholder="Mensagem (opcional)" name="message"
                    rows="4" maxlength="300" [ngClass]="{ 'is-invalid': !validContact && f.message.errors }" required></textarea>
                    <div *ngIf="!validContact && f.message.errors" class="invalid-feedback text-left">
                        <div *ngIf="f.message.errors.required">Por favor, informe a mensagem.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="w-100 pt-15 pr-20 text-right">
                    <button type="submit" id="subscribe" class="btn btn-primary btn-rounded" style="margin-right: 10px;" [disabled]="submitted">
                        Confirmar
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="submitted"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="w-100 pt-15 pr-20 text-left" *ngIf="returnMessage.length > 0">
                    <div  class="alert alert-dismissible" [ngClass]="msgReturnSuccess ? 'alert-info' : 'alert-danger'" data-auto-dismiss role="alert">
                        <button class="close" aria-label="close" data-dismiss="alert">&times;</button>
                        <span id="alertMsg">{{returnMessage}}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
