import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TextMaskModule } from './_helpers/mask/text.mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home';

import { ComingSoonComponent } from './components/coming-soon';
import { CoursesComponent } from './components/courses/courses.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExperimentalClassComponent } from './components/experimental-class/experimental-class.component';

import { AppRoutingModule } from './app.routing';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ComingSoonComponent,
    HeaderComponent,
    SidebarComponent,
    AboutUsComponent,
    CoursesComponent,
    ContactUsComponent,
    ContactUsFormComponent,
    TestimonialsComponent,
    ThankYouComponent,
    NotFoundComponent,
    ExperimentalClassComponent
  ],
  imports: [
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    TextMaskModule,
    NgbModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
