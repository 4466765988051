import { ExperimentalClassContact } from "./experimentalClassContact";

export class ExperimentalClassPostSchedule {
    public experimentalClassContact: ExperimentalClassContact;
    public scheduleDateSet: Date;
    public experimentalLevel: Number;
    public experimentalTarget: Number;

    constructor(contentResponse: any) {
        if (contentResponse) {
            if (contentResponse.experimentalClassContact) {
                this.experimentalClassContact = contentResponse.experimentalClassContact;
            }
    
            if (contentResponse.scheduleDateSet) {
                this.scheduleDateSet = contentResponse.scheduleDateSet;
            }

            if (contentResponse.experimentalLevel) {
                this.experimentalLevel = contentResponse.experimentalLevel;
            }

            if (contentResponse.experimentalTarget) {
                this.experimentalTarget = contentResponse.experimentalTarget;
            }
        }
    }
}
