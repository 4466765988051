import { ContactService } from './../../services/contact.service';
import { Contact } from './../../model/contact';
import { Component, OnInit } from '@angular/core';
import { map, catchError, first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EnumTypeResponse } from 'src/app/enum/EnumTypeResponse';
import { Router } from '@angular/router';
@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html'
})

export class ComingSoonComponent implements OnInit {
  contactForm;
  submitted = false;
  returnUrl: string;
  public validContact: boolean;
  public returnMessage = '';
  public msgReturnSuccess: boolean = false;
  private contact: Contact;
  dataReturn: any;
  public phoneMask: Array<string | RegExp>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private contactService: ContactService
  ) { 
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    // Initialize the register form
    this.onInitForm();
    this.returnUrl = '/';
  }

  onInitForm() {
    this.validContact = true;
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      message: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.validContact = true;

    // Errors
    this.returnMessage = '';

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.submitted = false;
      this.validContact = false;
      this.returnMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.contact = new Contact(this.contactForm.value);

    this.contactService.sendContact(this.contact)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == EnumTypeResponse.Success) {
        this.msgReturnSuccess = true;
      }
      this.validContact = this.msgReturnSuccess;
      this.returnMessage = dataReturn.message;
      this.submitted = false;
      if (this.msgReturnSuccess) {
        this.contactForm.reset();
      }
    }, error => {
      this.submitted = false;
      this.returnMessage = error;
    });
  }
}
