import { Component, OnInit } from '@angular/core';

@Component({ selector: 'about-us', templateUrl: './about-us.component.html' })
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
