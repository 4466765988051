<div class="container-fluid">
    <div class="row align-items-stretch">
        <div class="col-md-6 p-0" data-aos="fade" data-aos-duration="400">
            <div class="address-container bg2 h-100">
                <!--==== Grediant Overlay =====-->
                <div class="grediant-overlay-reverse opacity-85"></div>
                <!--==== Contect Us Text =====-->
                <div class="address-content">
                    <!--==== Address Text =====-->
                    <div class="address-info">
                        <h2>R. Conceição, 233 - Sala 916 - Conceição<br>Campinas - SP, CEP: 13010-050</h2>
                    </div>
                    <!--==== Phone Number =====-->
                    <div class="address-info">
                        <p>
                            <strong>WhatsApp</strong> : 
                            <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="text-white" title="Aprenda online">
                                +55 19 4107-1329
                            </a>
                        </p>
                        <!-- <p>
                            <strong>WhatsApp</strong> : 
                            <a href="https://api.whatsapp.com/send?phone=551941071329" target="_blank" class="text-white" title="Aprenda online">
                                +55 19 4107-1329
                            </a>
                        </p> -->
                    </div>
                    <!--==== Mail Address =====-->
                    <div class="address-info">
                        <p><strong>Contato</strong>: adm@englishcoffee.com.br</p>
                        <p><strong>Suporte</strong>: suporte@englishcoffee.com.br</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 p-0">
            <!--==== Google Map Area =====-->
            <div id="map">
                <iframe src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=English+Coffee+-+Inglês+em+Poucas+Horas+e+Sem+Gramática&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed" allowfullscreen=""></iframe>
            </div>
        </div>
    </div>
</div>