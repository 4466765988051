export class Contact {
    public name: string;
    public email: string;
    public phone: string;
    public message: string;

    constructor(contentResponse: any) {
        this.name = contentResponse.name;
        this.email = contentResponse.email;
        this.phone = contentResponse.phone;
        this.message = contentResponse.message;
    }
}
