import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((error: any) => {
        console.log(error);
        var errorMsg = '';
        
        // if (error.status == 401) {
        //   this.router.navigate(['/auth/login']);
        // }

        if (error.status == 500 ) {
          var errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          console.log(errorMsg);
          return throwError(error);
        }

        var errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        console.log(errorMsg);
        // if (error.status == 0) {
        //   this.router.navigate(['/auth/login']);
        // }
        return throwError(error);
      })
    );
  }
}
