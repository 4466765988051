import { Component, OnInit } from '@angular/core';

@Component({ selector: 'sd-testimonials', templateUrl: './testimonials.component.html' })
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
