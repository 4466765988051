import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html'
})
export class ThankYouComponent implements OnInit {
  private returnUrl: string = '/';
  public loaded: boolean = false;

  constructor(
    private router: Router,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document
  ) { 
  }

  ngOnInit(): void {
    this.returnUrl = '/';

    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
      <!-- Meta Pixel Code -->
      <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1072846866465727');
      fbq('track', 'PageView');
      fbq('track', 'Lead');
      </script>
      <noscript><img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1072846866465727&ev=PageView&noscript=1"
      /></noscript>
      <!-- End Meta Pixel Code -->    
    `;

    this._renderer2.appendChild(this._document.body, script);
    this.loaded = true;
  }
}
