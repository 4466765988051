import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    if (localStorage.getItem('refreshed') === null) {
      localStorage.clear();
      localStorage['refreshed'] = true;
      setTimeout(()=>{
        localStorage.clear();
      }, 1000*60*20);
      //window.location.replace('/');
    } else {
        localStorage.removeItem('refreshed');
    }
  }
  
  title = 'Site';
  public submitted = true;
}
